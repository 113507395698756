import React, { createContext } from "react";
import Routes from "./Routes";

type ContextProps = {};

export const AppContext = createContext({} as ContextProps);

function App() {
  return (
    <AppContext.Provider value={{}}>
      <Routes />
    </AppContext.Provider>
  );
}

export default App;
