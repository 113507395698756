import React, { useState } from "react";
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  UncontrolledDropdown,
} from "reactstrap";
import { BiMenuAltLeft, BiRightArrow } from "react-icons/bi";
import { Link } from "react-router-dom";
import useUser from "../hooks/useUser";

const Template: React.FC = ({ children }) => {
  const { user, logout } = useUser();
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen((oldState) => !oldState);

  return (
    <>
      <Navbar dark color="primary" expand>
        <div className="sidebar-toggle text-light mr-3">
          <BiMenuAltLeft onClick={toggle} size={25} />
        </div>
        <NavbarBrand>
          <i className="fa fa-code-branch"></i> Coinsamba Console
        </NavbarBrand>
        <Collapse isOpen={true} navbar>
          <Nav className="ml-auto" navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                {user?.alias}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={logout}>Logout</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
      <div className="d-flex">
        <nav className={`sidebar bg-dark ${isOpen && "toggled"}`}>
          <ul className="list-unstyled">
            <li>
              <Link to="/projects">
                <BiRightArrow /> Projetos
              </Link>
            </li>
          </ul>
        </nav>
        <div className="content p-4">{children}</div>
      </div>
    </>
  );
};

export default Template;
