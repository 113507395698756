import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { RouteProps } from "react-router";
import Projects from "./pages/Projects";
import useUser from "./hooks/useUser";

interface CustomRouteProps extends RouteProps {
  children?: React.ReactNode;
}

function PrivateRoute({ children, ...rest }: CustomRouteProps) {
  const { user } = useUser();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          <>{children}</>
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function OnlyGuestRoute({ children, ...rest }: CustomRouteProps) {
  const { user } = useUser();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !user ? (
          <>{children}</>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute exact path="/">
          <Projects />
        </PrivateRoute>
        <PrivateRoute exact path="/projects">
          <Projects />
        </PrivateRoute>
        <OnlyGuestRoute
          exact
          path="/auth/login"
          component={() => {
            window.location.href = "https://coinsamba.com/auth/login?r=console";
            return null;
          }}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
