import jsCookie from "../utils/jsCookie";

const PLATFORM_NAME = "coinsamba";

class Storage {
  help(prefix: string, variable: any, value = null) {
    const key = `@${PLATFORM_NAME}/${prefix}-${variable}`;
    if (value !== null) this.set(key, value);
    return this.get(key);
  }

  set(key: string, value: {} | null) {
    return jsCookie.set(key, value, 30);
  }

  get(key: string) {
    return jsCookie.get(key);
  }

  unset(key: string) {
    jsCookie.unset(key);
  }
}

const instance = new Storage();
export { instance as Storage };
