class Coinsamba {
  env: string;
  constructor() {
    this.env = process.env.NODE_ENV;
  }

  isDev() {
    return this.env === "development";
  }
}

const instance = new Coinsamba();

export { instance as Coinsamba };
