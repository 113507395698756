import axios from "axios";
import { getToken } from "./auth";

const env = process.env.NODE_ENV;

function getBase() {
  if (env === "development") return "http://localhost:3001";
  else if (env === "test") return "https://apistaging.coinsamba.com";
  return "https://api.coinsamba.com";
}

const api = axios.create({
  baseURL: getBase(),
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
