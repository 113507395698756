import { Storage } from "./Storage";

export const isAuthenticated = () => !!Storage.get("token");

export const isAdmin = () => isAuthenticated() && Storage.get(`admin`) !== null;

export const isModerator = () =>
  isAuthenticated() && (Storage.get(`moderator`) !== null || isAdmin());

export const getUser = () => JSON.parse(Storage.get(`user`));

export const getToken = () => Storage.get("token");

export const logout = () => {
  Storage.set("token", null);
  Storage.set("user", {});
  window.location.assign("/");
};
