import cogoToast from "cogo-toast";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Form,
  Input,
  Jumbotron,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import ProjectCard from "../components/ProjectCard";
import Template from "../components/Template";
import api from "../services/api";

// import { Container } from './styles';

const Projects: React.FC = () => {
  const [projects, setProjects] = useState([]);
  const [modal, setModal] = useState(false);

  const [formData, setFormData] = useState({});

  const toggle = () => setModal((oldState) => !oldState);

  const loadData = () =>
    api
      .get("/internal/console/projects")
      .then(({ data }) => {
        setProjects(data);
      })
      .catch((err) => console.log(err));

  useEffect(() => {
    loadData();
  }, []);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    api.post("/internal/console/project", formData).then(() => {
      loadData();
      cogoToast.success("Projeto criado com sucesso");
      toggle();
    });
  };

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = e;

    setFormData((oldState) => ({
      ...oldState,
      [name]: value,
    }));
  };

  return (
    <Template>
      <Modal toggle={toggle} isOpen={modal}>
        <ModalHeader>Novo projeto</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Label for="name">Nome</Label>
            <Input
              type="text"
              minLength={6}
              name="name"
              id="name"
              onChange={handleFormChange}
            />
            <Label for="description">Descrição</Label>
            <Input
              type="textarea"
              minLength={12}
              name="description"
              id="description"
              onChange={handleFormChange}
            />
            <Button block color="primary" className="mt-3" type="submit">
              Criar
            </Button>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Jumbotron>
        <h1 className="display-3">Coinsamba Console</h1>
        <p className="lead">
          Crie e administre seus projetos, chaves e compre mais créditos de
          acesso.
        </p>
      </Jumbotron>
      {projects.length === 0 && (
        <Alert color="primary">
          Nenhum projeto criado, experimente criar o seu primeiro para ter
          acesso aos dados do Coinsamba.
        </Alert>
      )}
      {projects.length >= 2 && (
        <Alert color="primary">
          Você atingiu o número máximo de projetos para sua conta.
        </Alert>
      )}
      <Alert color="info">
        Ainda estamos em fase de teste, por isso o serviço permanece gratuito
        sem previsão para uma versão paga.
      </Alert>
      <Row className="mb-3 pl-3 pr-3">
        <Button
          color="primary"
          onClick={toggle}
          disabled={projects.length >= 2}
          id="tooltipnewproject"
        >
          Novo projeto {projects.length}/2
        </Button>
        <UncontrolledTooltip target="tooltipnewproject">
          Você pode criar no máximo 2 projetos
        </UncontrolledTooltip>
        {/* <Button color="primary ml-auto" onClick={toggle} outline>
          Comprar créditos
        </Button> */}
      </Row>
      <Row>
        {projects.map(
          (e: { id: number; name: string; description: string }) => (
            <ProjectCard
              key={e.id}
              id={e.id}
              name={e.name}
              description={e.description}
              refresh={loadData}
            />
          )
        )}
      </Row>
    </Template>
  );
};

export default Projects;
