import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  ModalFooter,
  Form,
  Label,
  Input,
} from "reactstrap";
import api from "../services/api";
import cogoToast from "cogo-toast";
import Copy from "./Copy";
import DeleteKeyModal from "./DeleteKeyModal";
import DeleteProjectModal from "./DeleteProjectModal";

const ProjectCard: React.FC<{
  id: number;
  name: string;
  description: string;
  refresh: CallableFunction;
}> = ({ id, name, description, refresh }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenNewKey, setIsOpenNewKey] = useState(false);
  const [data, setData] = useState([]);
  const [newKeyValue, setNewKeyValue] = useState("");
  const [formData, setFormData] = useState({
    projectId: id,
  });

  const toggle = () => setIsOpen((oldState) => !oldState);
  const toggleNewKey = () => {
    setNewKeyValue("");
    setIsOpenNewKey((oldState) => !oldState);
  };

  const loadData = useCallback(
    () =>
      api
        .get("/internal/console/api-keys", {
          params: {
            projectId: id,
          },
        })
        .then(({ data }) => setData(data))
        .catch((err) => console.error(err)),
    [id]
  );

  useEffect(() => {
    if (isOpen) loadData();
  }, [isOpen, loadData]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    api
      .post("/internal/console/api-key", formData)
      .then(({ data }) => {
        setNewKeyValue(data.key);
        cogoToast.success("Chave criada");
        // setIsOpenNewKey(false);
        loadData();
      })
      .catch((err) => console.error(err));
  };

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = e;

    setFormData((oldState) => ({
      ...oldState,
      [name]: value,
    }));
  };

  return (
    <Col sm={6} className="mb-3">
      <Modal isOpen={isOpenNewKey} toggle={toggleNewKey}>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Label for="label">Nome da chave (label)</Label>
            <Input
              type="text"
              minLength={6}
              name="label"
              className="mb-3"
              onChange={handleFormChange}
            />
            <Button color="primary" type="submit" disabled={!!newKeyValue}>
              Criar
            </Button>
            {newKeyValue && (
              <>
                <hr />
                <Input disabled value={newKeyValue} bsSize="sm" />
                <small>
                  Chave gerada <Copy toCopy={newKeyValue} />
                </small>
              </>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggleNewKey}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal toggle={toggle} isOpen={isOpen}>
        <ModalHeader>Chaves de acesso</ModalHeader>
        <ModalBody>
          <Table bordered size="sm" responsive striped>
            <thead>
              <tr>
                <td>Nome</td>
                <td>Data</td>
                <td>-</td>
              </tr>
            </thead>
            <tbody>
              {data.map(
                (e: { id: number; label: string; createdAt: string }) => (
                  <tr>
                    <td>{e.label}</td>
                    <td>{e.createdAt}</td>
                    <td>
                      <DeleteKeyModal
                        id={e.id}
                        label={e.label}
                        refresh={loadData}
                      />
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <DeleteProjectModal
            id={id}
            name={name}
            disabled={data.length > 0}
            refresh={refresh}
          />
          <Button color="primary" onClick={toggleNewKey}>
            Nova chave
          </Button>
          <Button color="danger" onClick={toggle}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
      <Card>
        <CardBody>
          <CardTitle tag="h5">{name}</CardTitle>
          <CardText>{description}</CardText>
          <Button onClick={toggle}>Gerenciar</Button>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ProjectCard;
