import cogoToast from "cogo-toast";
import React from "react";
import { Button } from "reactstrap";
import api from "../services/api";

// import { Container } from './styles';

const DeleteProjectModal: React.FC<{
  name: string;
  id: number;
  disabled: boolean;
  refresh: CallableFunction;
}> = ({ name, id, disabled, refresh }) => {
  const handleDelete = () => {
    const r = window.confirm(`Deseja excluir o projeto "${name}"`);

    if (r)
      api
        .delete("/internal/console/project", {
          params: {
            id,
          },
        })
        .then(() => {
          cogoToast.info("Projeto deletado");
          refresh();
        })
        .catch((err) => console.error(err));
  };

  return (
    <Button
      color="danger"
      onClick={handleDelete}
      className="mr-auto"
      disabled={disabled}
    >
      Excluir Projeto
    </Button>
  );
};

export default DeleteProjectModal;
