import { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { Storage } from "../services/Storage";
import { useHistory } from "react-router-dom";

export interface IUser {
  email: string;
  name: string;
  alias: string;
  admin?: boolean;
  moderator?: boolean;
}

const useUser = () => {
  const history = useHistory();
  const [user, setUser] = useState(
    Storage.get("token") ? jwt_decode(Storage.get("token")) : null
  ) as [null | IUser, React.Dispatch<React.SetStateAction<null | IUser>>];

  const logout = () => {
    Storage.unset("token");
    setUser(null);
    history.push("/auth/login");
  };

  const login = (token: string) => {
    Storage.set("token", token);
    setUser(jwt_decode(token));
    history.push("/");
  };

  return { user, login, logout };
};

export default useUser;
