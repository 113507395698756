import cogoToast from "cogo-toast";
import React from "react";
import { IoIosTrash } from "react-icons/io";
import api from "../services/api";

const DeleteKeyModal: React.FC<{
  label: string;
  id: number;
  refresh: CallableFunction;
}> = ({ label, id, refresh }) => {
  const handleDelete = () => {
    const r = window.confirm(`Deseja excluir a chave ${label}`);

    if (r)
      api
        .delete("/internal/console/api-key", {
          params: {
            id,
          },
        })
        .then(() => {
          cogoToast.info("Chave deletada");
          refresh();
        })
        .catch((err) => console.error(err));
  };

  return <IoIosTrash color="red" onClick={handleDelete} cursor="pointer" />;
};

export default DeleteKeyModal;
