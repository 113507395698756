import { Coinsamba } from "../services/Coinsamba";

function set(key: string, value: any, days: number) {
  try {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setDate(date.getDate() + days);
      expires = "; expires=" + date;
    }
    document.cookie = `${key}=${JSON.stringify(
      value
    )};expires=${expires};domain=${
      Coinsamba.isDev() ? "localhost" : ".coinsamba.com"
    };path=/`;
  } catch (error) {
    return null;
  }
}

function get(key: string) {
  try {
    var nameEQ = key + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) {
        return JSON.parse(c.replace(nameEQ, ""));
      }
    }
  } catch (error) {
    return null;
  }
}

function unset(key: string) {
  set(key, null, -30);
}

export default {
  get,
  set,
  unset,
};
